import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import debounce from 'lodash/debounce';
import {
  GetAnnouncementReactionKindsResponseModel,
  GetPublishedAnnouncementsResponseModel
} from '@elfsight-universe/service-core-contracts/announcement';
import { useUpdateAnnouncementReactionsMutation } from '@api';

export type IAnnouncementReactionsContext = {
  reactionsState: AnnouncementReactionsState;
  announcementReactionKinds: GetAnnouncementReactionKindsResponseModel[];
  toggleReaction: (reactionPid: string) => void;
  displayedReactionBlocks: string[];
  displayAddReactionBlock: boolean;
};

export const AnnouncementReactionsContext =
  createContext<IAnnouncementReactionsContext | null>(null);

type AnnouncementReactionsProviderProps = PropsWithChildren<{
  announcement: GetPublishedAnnouncementsResponseModel;
  announcementReactionKinds: GetAnnouncementReactionKindsResponseModel[];
}>;

type AnnouncementReactionsState = Record<
  string,
  {
    numberOfReactions: number;
    userHasReacted: boolean;
  }
>;

export function AnnouncementReactionsProvider({
  announcement,
  announcementReactionKinds,
  children
}: AnnouncementReactionsProviderProps) {
  const [reactionsState, setReactionsState] =
    useState<AnnouncementReactionsState>(
      getInitialAnnouncementReactionsState(
        announcement,
        announcementReactionKinds
      )
    );
  const reactionsStateRef = useRef(reactionsState);
  useEffect(() => {
    reactionsStateRef.current = reactionsState;
  }, [reactionsState]);

  const { mutate: updateReactions } = useUpdateAnnouncementReactionsMutation();
  const debouncedUpdateReactions = useCallback(
    debounce(() => {
      const announcementReactionKindPids = announcementReactionKinds
        .map(({ pid }) => pid)
        .filter((pid) => reactionsStateRef.current[pid].userHasReacted);

      updateReactions({
        announcementPid: announcement.pid,
        announcementReactionKindPids
      });
    }, 500),
    []
  );

  const toggleReaction = (reactionKindPid: string) => {
    setReactionsState((prevReactionsState) => {
      const prevReactionState = prevReactionsState[reactionKindPid];

      const newReactionState = prevReactionState.userHasReacted
        ? {
            numberOfReactions: prevReactionState.numberOfReactions - 1,
            userHasReacted: false
          }
        : {
            numberOfReactions: prevReactionState.numberOfReactions + 1,
            userHasReacted: true
          };

      return {
        ...prevReactionsState,
        [reactionKindPid]: newReactionState
      };
    });

    debouncedUpdateReactions();
  };

  const displayAddReactionBlock = Object.keys(reactionsState).some(
    (reactionPid) => reactionsState[reactionPid].numberOfReactions <= 0
  );
  const displayedReactionBlocks = announcementReactionKinds
    .map(({ pid }) => pid)
    .filter((reactionPid) => reactionsState[reactionPid].numberOfReactions > 0);

  return (
    <AnnouncementReactionsContext.Provider
      value={{
        reactionsState,
        toggleReaction,
        displayAddReactionBlock,
        displayedReactionBlocks,
        announcementReactionKinds
      }}
    >
      {children}
    </AnnouncementReactionsContext.Provider>
  );
}

export function useAnnouncementReactionsContext() {
  const context = useContext(AnnouncementReactionsContext);

  if (context === null) {
    throw new Error(
      '`useAnnouncementReactionsContext` must be nested inside an `AnnouncementReactionsProvider`.'
    );
  }

  return context;
}

function getInitialAnnouncementReactionsState(
  announcement: GetPublishedAnnouncementsResponseModel,
  announcementReactionKinds: GetAnnouncementReactionKindsResponseModel[]
) {
  const announcementReactionsState: AnnouncementReactionsState = {};

  for (const { pid } of announcementReactionKinds) {
    const reactionSummary = announcement.reactionsSummary.find(
      ({ announcementReactionKindPid }) => announcementReactionKindPid === pid
    );

    announcementReactionsState[pid] = reactionSummary
      ? {
          numberOfReactions: reactionSummary.numberOfReactions,
          userHasReacted: reactionSummary.userHasReacted
        }
      : { numberOfReactions: 0, userHasReacted: false };
  }

  return announcementReactionsState;
}
