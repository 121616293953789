import { IsArray, IsUUID } from 'class-validator';

export const UPDATE_ANNOUNCEMENT_REACTIONS_PATH =
  'announcement/update-announcement-reactions';

export class UpdateAnnouncementReactionsRequest {
  @IsUUID()
  announcementPid: string;

  @IsArray()
  @IsUUID(undefined, { each: true })
  announcementReactionKindPids: string[];
}

export type UpdateAnnouncementReactionsResponseError =
  'ANNOUNCEMENT_DOES_NOT_EXISTS';
