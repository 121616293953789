import { Exclude, Expose, Type } from 'class-transformer';

export const GET_ANNOUNCEMENT_REACTION_KINDS_PATH =
  'announcement/get-announcement-reaction-kinds';

@Exclude()
export class GetAnnouncementReactionKindsResponse {
  @Expose()
  @Type(() => GetAnnouncementReactionKindsResponseModel)
  payload: GetAnnouncementReactionKindsResponseModel[];
}

@Exclude()
export class GetAnnouncementReactionKindsResponseModel {
  @Expose()
  pid: string;

  @Expose()
  title: string;

  @Expose()
  alias: string;

  @Expose()
  iconURL: string;

  @Expose()
  order: number;
}
