import styled from 'styled-components';
import NextImage from 'next/image';
import {
  formatCompactNumber,
  getColorWithAlpha,
  Tooltip,
  Typography
} from '@elfsight-universe/ui-common';
import { useAnnouncementReactionsContext } from '@modules/announcements/announcement-reactions-provider';

export type AnnouncementReactionProps = {
  reactionKindPid: string;
};

export function AnnouncementReaction({
  reactionKindPid
}: AnnouncementReactionProps) {
  const { toggleReaction, reactionsState, announcementReactionKinds } =
    useAnnouncementReactionsContext();
  const { userHasReacted, numberOfReactions } = reactionsState[reactionKindPid];
  const announcementReactionKind = announcementReactionKinds.find(
    ({ pid }) => pid === reactionKindPid
  );

  return (
    <Tooltip content={userHasReacted ? 'Remove Reaction' : 'Add Reaction'}>
      <Reaction
        _userHasReacted={userHasReacted}
        onTouchStart={() => toggleReaction(reactionKindPid)}
        onClick={() => toggleReaction(reactionKindPid)}
      >
        <NextImage
          width={20}
          height={20}
          alt={announcementReactionKind!.title}
          src={announcementReactionKind!.iconURL}
        />
        <Typography variant="captionBold">
          {formatCompactNumber(numberOfReactions)}
        </Typography>
      </Reaction>
    </Tooltip>
  );
}

const Reaction = styled.div<{ _userHasReacted: boolean }>`
  background: ${({ theme, _userHasReacted }) => (_userHasReacted ? getColorWithAlpha(theme.colors.accent, 0.1) : theme.colors.gray5)};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 44px;

  span {
    color: ${({ theme, _userHasReacted }) => (_userHasReacted ? theme.colors.accent : theme.colors.gray50)};
  }

  :hover {
    cursor: pointer;

    span {
      color: ${({ theme, _userHasReacted }) => (_userHasReacted ? theme.colors.accent : theme.colors.black)};
    }
  }
`;
