import { Expose } from 'class-transformer';

export class Announcement {
  @Expose()
  pid: string;

  @Expose()
  createdAt: Date;

  @Expose()
  title: string;

  @Expose()
  body: string;

  @Expose()
  ctaButtonText: string;

  @Expose()
  ctaButtonLink: string;

  @Expose()
  imageURL?: string;
}
