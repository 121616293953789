import styled from 'styled-components';
import NextImage from 'next/image';
import { Tooltip } from '@elfsight-universe/ui-common';
import { useAnnouncementReactionsContext } from '@modules/announcements/announcement-reactions-provider';

export type AnnouncementAddReactionItemProps = {
  reactionKindPid: string;
  onClick: () => void;
};

export function AnnouncementAddReactionItem({
  reactionKindPid,
  onClick
}: AnnouncementAddReactionItemProps) {
  const { toggleReaction, announcementReactionKinds } =
    useAnnouncementReactionsContext();
  const announcementReactionKind = announcementReactionKinds.find(
    ({ pid }) => pid === reactionKindPid
  );

  const handleClick = () => {
    toggleReaction(reactionKindPid);
    onClick();
  };

  return (
    <Tooltip
      content={announcementReactionKind!.title}
      containerStyle={{ zIndex: 10000000 }}
    >
      <Container onTouchStart={handleClick} onClick={handleClick}>
        <NextImage
          width={28}
          height={28}
          alt={announcementReactionKind!.title}
          src={announcementReactionKind!.iconURL}
        />
      </Container>
    </Tooltip>
  );
}

const Container = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 84px;

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.gray5};
  }
`;
