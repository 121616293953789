import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Icon, Popover } from '@elfsight-universe/ui-common';
import AddReactionIcon from '@icons/add-reaction.svg';
import { AnnouncementAddReactionItem } from '@modules/announcements/components/announcement-add-reaction-item';
import { useAnnouncementReactionsContext } from '@modules/announcements/announcement-reactions-provider';

export function AnnouncementAddReaction() {
  const { announcementReactionKinds } = useAnnouncementReactionsContext();
  const [isOpen, setIsOpen] = useState(false);

  const scheduledActionTimeoutRef = useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const open = useCallback(() => {
    setIsOpen(true);
    cancelScheduledAction();
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    cancelScheduledAction();
  }, []);

  const scheduleOpening = useCallback(() => {
    cancelScheduledAction();
    scheduledActionTimeoutRef.current = setTimeout(() => {
      setIsOpen(true);
      scheduledActionTimeoutRef.current = undefined;
    }, 300);
  }, []);

  const scheduleClosing = useCallback(() => {
    cancelScheduledAction();
    scheduledActionTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
      scheduledActionTimeoutRef.current = undefined;
    }, 300);
  }, []);

  const cancelScheduledAction = useCallback(() => {
    if (scheduledActionTimeoutRef.current) {
      clearTimeout(scheduledActionTimeoutRef.current);
      scheduledActionTimeoutRef.current = undefined;
    }
  }, []);

  useEffect(() => cancelScheduledAction, []);

  return (
    <Popover
      containerStyle={{ borderRadius: 54, zIndex: 1000000 }}
      placement="top"
      isOpen={isOpen}
      content={
        <PopoverContent
          onMouseEnter={cancelScheduledAction}
          onMouseLeave={scheduleClosing}
        >
          {announcementReactionKinds.map(({ pid }) => (
            <AnnouncementAddReactionItem
              key={pid}
              reactionKindPid={pid}
              onClick={close}
            />
          ))}
        </PopoverContent>
      }
    >
      <AddReaction
        onClick={open}
        onMouseEnter={scheduleOpening}
        onMouseLeave={scheduleClosing}
      >
        <Icon component={AddReactionIcon} size={20} />
      </AddReaction>
    </Popover>
  );
}

const AddReaction = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  padding: 2px 8px;
  border-radius: 44px;

  svg {
    fill: ${({ theme }) => theme.colors.gray50};
  }

  :hover {
    cursor: pointer;

    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const PopoverContent = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px;
`;
