import { useQuery } from '@tanstack/react-query';
import {
  GET_ANNOUNCEMENT_REACTION_KINDS_PATH,
  GetAnnouncementReactionKindsResponse
} from '@elfsight-universe/service-core-contracts/announcement';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useAnnouncementReactionKindsQuery() {
  const { isAuthenticated } = useUser();

  return useQuery<GetAnnouncementReactionKindsResponse>(
    [GET_ANNOUNCEMENT_REACTION_KINDS_PATH],
    () =>
      client
        .get(GET_ANNOUNCEMENT_REACTION_KINDS_PATH)
        .json<GetAnnouncementReactionKindsResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
