import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UPDATE_ANNOUNCEMENT_REACTIONS_PATH,
  UpdateAnnouncementReactionsRequest,
  UpdateAnnouncementReactionsResponseError
} from '@elfsight-universe/service-core-contracts/announcement';
import { client } from '../client';

export function useUpdateAnnouncementReactionsMutation(
  options: UseMutationOptions<
    Response,
    UpdateAnnouncementReactionsResponseError,
    UpdateAnnouncementReactionsRequest
  > = {}
) {
  return useMutation<
    Response,
    UpdateAnnouncementReactionsResponseError,
    UpdateAnnouncementReactionsRequest
  >((dto) => client.post(UPDATE_ANNOUNCEMENT_REACTIONS_PATH, { json: dto }), {
    retry: false,
    ...options
  });
}
