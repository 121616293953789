import { MouseEvent, useCallback } from 'react';
import styled, { css } from 'styled-components';
import parse, { DOMNode } from 'html-react-parser';
import isSameYear from 'date-fns/isSameYear';
import { Icon, Link, Typography } from '@elfsight-universe/ui-common';
import {
  GetAnnouncementReactionKindsResponseModel,
  GetPublishedAnnouncementsResponseModel
} from '@elfsight-universe/service-core-contracts/announcement';
import { formatRelativeDate } from '@utils';
import AngleBoldRight16 from '@icons/angle-bold-right-16.svg';
import { AnnouncementsReactions } from '@modules/announcements/announcements-reactions';
import { AnnouncementReactionsProvider } from '@modules/announcements/announcement-reactions-provider';
import {
  useAnnouncementClickLogging,
  useAnnouncementViewLogging
} from './utils';
import { VideoPlayerDialog, AnnouncementImage } from './components';

export type AnnouncementCardProps = {
  announcement: GetPublishedAnnouncementsResponseModel;
  announcementReactionKinds: GetAnnouncementReactionKindsResponseModel[];
};

export function AnnouncementCard({
  announcement,
  announcementReactionKinds,
  ...forwardingProps
}: AnnouncementCardProps) {
  const {
    pid,
    title,
    body,
    imageURL,
    createdAt,
    ctaButtonText,
    ctaButtonLink
  } = announcement;

  const containerInViewObservingRef = useAnnouncementViewLogging(pid);
  const logTitleAnnouncementClick = useAnnouncementClickLogging(pid, 'title');
  const logCTAAnnouncementClick = useAnnouncementClickLogging(pid, 'cta');
  const logTextLinkAnnouncementClick = useAnnouncementClickLogging(
    pid,
    'text-link'
  );

  const showYear = !isSameYear(new window.Date(), new window.Date(createdAt));
  const formattedDate = formatRelativeDate(createdAt, showYear);

  const bodyWithVideoPlayer = parse(body, {
    replace: replaceNodes(pid, ctaButtonLink)
  });

  const logBodyTextLinkClick = useCallback(
    function logBodyLinkClick(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const isTextLink =
        target.tagName === 'A' && !!target.closest('p, ul, li');
      if (isTextLink) {
        logTextLinkAnnouncementClick();
      }
    },
    [logTextLinkAnnouncementClick]
  );

  return (
    <Container ref={containerInViewObservingRef} {...forwardingProps}>
      {imageURL && (
        <AnnouncementImage
          announcementPid={pid}
          link={ctaButtonLink}
          src={imageURL}
          isMainImage
        />
      )}

      <Header>
        <TitleLink
          href={ctaButtonLink}
          $withUnderline={!!ctaButtonLink}
          onClick={logTitleAnnouncementClick}
          onContextMenu={logTitleAnnouncementClick}
        >
          {parse(title)}
        </TitleLink>

        <Date variant="captionSmall" color="gray50">
          {formattedDate}
        </Date>
      </Header>

      <Body onClick={logBodyTextLinkClick} onContextMenu={logBodyTextLinkClick}>
        {bodyWithVideoPlayer}
      </Body>

      {ctaButtonLink && ctaButtonText && (
        <CTAWrapper>
          <CTA
            href={ctaButtonLink}
            color="accent"
            onClick={logCTAAnnouncementClick}
            onContextMenu={logCTAAnnouncementClick}
          >
            {ctaButtonText}
          </CTA>
          <StyledIcon component={AngleBoldRight16} size={12} fill="accent" />
        </CTAWrapper>
      )}

      <AnnouncementReactionsProvider
        announcement={announcement}
        announcementReactionKinds={announcementReactionKinds}
      >
        <AnnouncementsReactions />
      </AnnouncementReactionsProvider>
    </Container>
  );
}

const replaceNodes =
  // eslint-disable-next-line react/display-name
  (announcementPid: string, ctaLink?: string) => (domhandlerNode: DOMNode) => {
    if (
      domhandlerNode.type === 'tag' &&
      domhandlerNode.name === 'div' &&
      domhandlerNode.attribs.hasOwnProperty('data-youtube-video') &&
      domhandlerNode.childNodes[0].type === 'tag'
    ) {
      const videoURL = domhandlerNode.childNodes[0].attribs.src;
      return (
        <VideoPlayerDialog
          announcementPid={announcementPid}
          videoUrl={videoURL}
        />
      );
    }

    if (domhandlerNode.type === 'tag' && domhandlerNode.name === 'img') {
      return (
        <AnnouncementImage
          announcementPid={announcementPid}
          link={ctaLink}
          src={domhandlerNode.attribs.src}
        />
      );
    }
  };

const Container = styled.div`
  padding: 24px;
  overflow: hidden;
  word-break: break-word;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
`;

const Date = styled(Typography)`
  flex-shrink: 0;
  line-height: 1;
  margin-top: 6px;
`;

const TitleLink = styled(Link)<{ $withUnderline?: boolean }>`
  ${({ theme }) => theme.font.title3Semibold};

  &:hover {
    ${({ $withUnderline }) =>
      $withUnderline
        ? css`
      cursor: pointer;
      text-decoration: underline;
        `
        : css`
      cursor: default;
      text-decoration: none;
        `}
`;

const CTA = styled(Link)`
  ${({ theme }) => theme.font.captionBold};
`;

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
  margin-top: 8px;
`;

const StyledIcon = styled(Icon)`
  margin-top: 2px;
`;

const Body = styled.div`
  margin-top: 8px;

  ul, ol {
    margin: 0;
    padding: 0 0 0 21px;
  }

  li {
    margin: 8px 0;
    padding: 0;
  }

  p {
    margin: 8px 0;
    ${({ theme }) => theme.font.caption};
  }

  strong {
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    ${({ theme }) => theme.font.caption};
    border-left: 2px solid ${({ theme }) => theme.colors.gray30};
    margin: 8px 0;
    padding-left: 8px;
  }
`;
