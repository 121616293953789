import styled from 'styled-components';
import {
  AnnouncementAddReaction,
  AnnouncementReaction
} from '@modules/announcements/components';
import { useAnnouncementReactionsContext } from '@modules/announcements/announcement-reactions-provider';

export function AnnouncementsReactions() {
  const { displayAddReactionBlock, displayedReactionBlocks } =
    useAnnouncementReactionsContext();

  return (
    <Container>
      {displayedReactionBlocks.map((reactionKindPid) => (
        <AnnouncementReaction
          key={reactionKindPid}
          reactionKindPid={reactionKindPid}
        />
      ))}

      {displayAddReactionBlock && <AnnouncementAddReaction />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 20px;
`;
